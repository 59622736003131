export const ENVIRONMENT_URLS = {
    api: {
        search: '/user/search/master/',
        careers: '/user/career/listing/',
        projects: '/user/project/',
        careersSearch: '/user/search/master/',
        ç: '/user/career/listing/',
        companiesForm: '/public/sales/enquiry/',
        event: '/user/event/listing/',
        initiatives: '/user/initiative/listing/',
        feed: '/user/feed/',
        connectFeed: '/user/connect/request/',
        presignedUrl: '/utils/get_presigned_upload_url',
        logoServer: 'https://logo.clearbit.com',
        public: {
            country: '/public/reference/type/country/',
            region: '/public/reference/type/region/',
            organisation: '/public/organisation/',
            city: '/public/reference/type/city/',
            currency: '/public/reference/type/currency/',
            skills: '/public/reference/type/skill/',
            languages: '/public/reference/type/language/',
            primaryFunctions: '/public/reference/type/function-primary',
            primaryIndustries: '/public/reference/type/industry-primary',
            secondaryIndustries: '/public/reference/type/industry-secondary',
            careers: '/public/career/listing/',
            event: '/public/event/listing/',
            opportunity: '/public/opportunity/',
            initiatives: '/public/initiative/listing/',
            questions: '/public/forum/question/',
            contact: '/public/support/request/',
            requests: '/public/forum/question/',  
            partners: '/public/content/partners',
            strength: '/public/reference/type/strength',
            referenceTypesAll: '/public/reference/type/all/',
            referenceTypeRole: '/public/reference/type/career/role/',
            referenceTypeCause: '/public/reference/type/cause',
            // changePassword: '/password/change',
            referral: '/public/user/referral/',
            publicEnquiry: '/public/sales/enquiry/',
            presignedUrl: '/public/storage/presigned/',
            careerReferral: '/public/user/career/share/',
            eventReferral: '/public/user/event/share/',
            experienceReferral: '/public/user/initiative/share/',
            companyEnrichment: 'https://companyenrichment.abstractapi.com/v1/',
            companyLogo: 'https://logo.clearbit.com/',
            organisationSearch: '/public/organisation/search/',
            phoneCode:'/public/reference/type/phone-code'
        },
        user: {
            user: '/user/user/',
            // TODO - Can we add this to auth group @varun
            basicUser: '/auth/user/',
            profile: '/user/user/profile/',
            country: '/user/user/profile/country/status/',
            profileTracker: '/user/user/profile/tracker/',
            details: '/user/user/profile/detail/',
            credential: '/user/user/profile/credential/',
            phone: '/user/user/profile/contact/phone/',
            experience: '/user/user/profile/experience/',
            education: '/user/user/profile/education/',
            language: '/user/user/profile/language/',
            request: '/user/user/profile/request/',
            offer: '/user/user/profile/offer/',
            skill: '/user/user/profile/skill/',
            followOrg: '/user/organisation/follower/',
            followUser: '/user/profile/follower/user/',
            post: '/user/post/',
            article: '/user/article/',
            linkedin: '/user/user/profile/linkedin/',
            contact: '/user/support/request/',
            role: '/user/user/profile/career/role/',
            cv: '/user/user/profile/cv/',
            languageLevel: '/user/user/profile/type/language/level',
            messaging: '/user/user/messaging',
            referral: '/user/user/referral/',
            careerReferral: '/user/career/share/',
            eventReferral: '/user/event/share/',
            experienceReferral: '/user/initiative/share/',
            userEnquiry: '/user/sales/enquiry/',
            employee: '/user/employee/',
            organisation: '/user/organisation/'
        },
        organisation: {
            loggedOutCareer: '/public/career/listing/',
            loggedInCareer: '/user/career/listing/',
            loggedOutEvent: '/public/event/listing/',
            loggedInEvent: '/user/event/listing/',
            loggedOutInitiative: '/public/initiative/listing/',
            loggedInInitiative: '/user/initiative/listing/',
            organisation: '/public/organisation/',
            organisationSearch: '/organisation/organisation/search/',
            organisationContent: '/public/organisation/content/',
        },
        auth: {
            login: '/auth/login/',
            googleLogin: '/auth/login/social/google/',
            googleConnect: '/auth/social/google/',
            linkedinLogin: '/auth/login/social/linkedin/',
            register: '/auth/register/',
            forgotPassword: '/auth/password/reset/',
            resetPassword: '/auth/password/reset/confirm/',
            logout: '/auth/logout/',
            logoutAllDevices: '/auth/logoutall/',
            emailVerification: '/auth/email/verification/',
            resendVerificationEmail: '/auth/register/resend-email/',
            changeEmail: '/auth/email/change/',
            phoneNumberVerification: '/auth/phone/verification/',
            resendPhoneNumberVerificationCode: '/auth/phone/verification/resend/',
            changePhoneNumber: '/auth/phone/change/',
            changePassword: '/auth/password/change/',
            basicAuthUser: '/auth/user/',
            employee: '/user/employee/'
        },
        career: {
            externalApply: '/user/career/application/external/',
            expressInterest: '/user/career/interest/event/',
            removeCv: '/user/career/application-remove-cv/',
            removeCl: '/user/career/application-remove-cl/',
            presignedUrl: '/user/storage/presigned/',
            application: '/user/career/application/',
            credentials: '/user/user/profile/credential/',
            details: '/user/user/profile/detail/',
            reaction: '/user/career/reaction/',
            comment: '/user/career/comment/',
            save: '/user/career/save/',
            loggedOutSimilar: '/public/career/similar/',
            loggedInSimilar: '/user/career/similar/',
            logggedOutListing: '/public/career/listing/',
            logggedInListing: '/user/career/listing/',
            commentVote: '/user/career/comment/vote/',
            advert: '/public/advert/listing/'
        },
        events: {
            register: '/user/event/application/external/',
            expressInterest: '/user/event/interest/event/',
            reaction: '/user/event/reaction/',
            comment: '/user/event/comment/',
            save: '/user/event/save/',
            loggedOutSimilar: '/public/event/similar/',
            loggedInSimilar: '/user/event/similar/',
            loggedOutListing: '/public/event/listing/',
            loggedInListing: '/user/event/listing/',
            commentVote: '/user/event/comment/vote/'
        },
        initiative: {
            expressInterest: '/user/initiative/interest/event/',
            register: '/user/initiative/application/external/',
            reaction: '/user/initiative/reaction/',
            comment: '/user/initiative/comment/',
            save: '/user/initiative/save/',
            loggedOutSimilar: '/public/initiative/similar/',
            loggedInSimilar: '/user/initiative/similar/',
            loggedOutListing: '/public/initiative/listing/',
            loggedInListing: '/user/initiative/listing/',
            commentVote: '/user/initiative/comment/vote/',
        },
        contract: {
            expressInterest: '/user/contract/interest/event/',
            register: '/user/contract/application/external/',
            reaction: '/user/contract/reaction/',
            comment: '/user/contract/comment/',
            save: '/user/contract/save/',
            loggedOutSimilar: '/public/contract/similar/',
            loggedInSimilar: '/user/contract/similar/',
            loggedOutListing: '/public/contract/listing/',
            loggedInListing: '/user/contract/listing/',
            commentVote: '/user/contract/comment/vote/',
        },
        // wagtail api
        media: {
            mediaPage: '/user/content/pages/community/',
            pages: '/public/content/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
            contentCategory: '/user/content/category'
        },
        insight: {
            insightPage: '/user/content/category/insights/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        news: {
            newsPage: '/user/content/category/news/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        update: {
            updatePage: '/user/content/category/updates/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        resources: {
            resourcePage: '/user/content/category/resources/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        // TODO - Resources missing
        // resources: {
        //     resourceePage: '/user/content/category/resources/',
        //     pages: '/pages',
        //     category: '/list/category',
        //     trending: '/user/content/list/category/trending/',
        //     reaction: '/user/content/pages/reaction/',
        //     comment: '/user/content/pages/comment/',
        // },
        stories: {
            storyPage: '/user/content/category/stories/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        community: {
            communityPage: '/user/content/category/community/',
            pages: '/pages',
            category: '/list/category',
            trending: '/user/content/list/category/trending/',
            reaction: '/user/content/pages/reaction/',
            comment: '/user/content/pages/comment/',
        },
        forum: {
            requests: '/user/forum/question/',
            comment: '/user/forum/question/comment/',
            answerComment: '/user/forum/answer/comment/',
            answerCommentVote: '/user/forum/answer/comment/vote/',
            answerVote: '/user/forum/answer/vote/',
            vote: '/user/forum/question/vote/',
            responseAnswers: '/user/forum/answer/',
            newAnswer: '/user/forum/answer/',
            responses: '/user/forum/responses',
            reaction: '/user/forum/question/reaction/',
            answerReaction: '/user/forum/answer/reaction/',
            save: '/user/forum/save/',
            answerSave: '/user/forum/answer/save/',
            commentVote: '/user/forum/question/comment/vote/',

        },
        opportunity: {
            requests: '/user/opportunity/',
            expressInterest: '/user/opportunity/interest/event/',
            responseAnswers: '/user/opportunity/comment/',
            newAnswer: '/opportunity/answer',
            responses: '/opportunity/responses',
            response: '/user/opportunity/response/',
            reaction: '/user/opportunity/reaction/',
            loggedOutComment: '/public/opportunity/comment/',
            loggedInComment: '/user/opportunity/comment/',
            vote: '/user/opportunity/vote/',
            save: '/user/opportunity/save/',
            loggedOutSimilar: '/public/opportunity/similar/',
            loggedInSimilar: '/user/opportunity/similar/',
            commentVote: '/user/opportunity/comment/vote/',
        },
        onboarding: {
            password: '/password/change/',
            interest: '/user/user/profile/interest/service/',
            interestsBulk: '/user/user/profile/interest/services/bulk/',
            opportunityInterest: '/user/user/profile/interest/opportunity-type/',
            peopleInterest: '/user/user/profile/interest/people/',
            serviceInterest: '/user/user/profile/interest/service/',
            offer: '/user/user/profile/offer/',
            request: '/user/user/profile/request/',
            topic: '/user/user/profile/topic/',
            cause: '/user/user/profile/interest/cause/',
            strength: '/user/user/profile/strength/',
            topicList: '/public/reference/type/topic/',
            orgTypeReference: '/public/organisation/type/',
            orgType: '/user/user/profile/interest/organisation-type/',
            industryPrimary: '/user/user/profile/interest/industry-primary/',
            industrySecondary: '/user/user/profile/interest/industry-secondary/',
            countryStatus: '/user/user/profile/type/country/status/',
            countryStatusBulkDelete: '/user/user/profile/country/bulk_delete/',
            userProfileReferenceType: '/public/reference/type/user/profile/',
            trackerStages: '/user/user/profile/type/tracker/stage/',
            language: '/user/user/profile/language/'
        },
        analytics: {
            impressions: '/analytics/impression/',
            page: '/analytics/page/',
            event: '/analytics/event/'
        },
        interest: {
            career: '/public/career/interest/event/',
            event: '/public/event/interest/event/',
            initiative: '/public/initiative/interest/event/',
            opportunity: '/public/opportunity/interest/event/',
        },
        post: {
            save: '/user/post/save/',
            comment: '/user/post/comment/',
            commentVote: '/user/post/comment/vote/'
        },
        content: {
            save: '/user/content/pages/save/',
            comment: '/user/content/pages/comment/',
            commentVote: '/user/content/pages/comment/vote/'
        },
        article: {
            save: '/user/article/save/',
            comment: '/user/article/comment/',
            commentVote: '/user/article/comment/vote/'
        },
        project: {
            comment: '/user/project/comment/',
            commentVote: '/user/project/comment/vote/',
            interest: '/user/project/interest/event/',
            follow: '/user/project/follower/',
            projectVote:'/user/project/vote/',
            unfollow: '/user/project/follower/',
            updateComment: '/user/project/update/comment/',
            expressInterest: '/user/project/interest/event/',
        }
    },
}
