import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, catchError, shareReplay, tap } from 'rxjs/operators';
import { AuthToken } from './types/auth-token.model';
import { ProfileService } from '../profile/profile.service';
import { PubSub } from 'pubsub-js';
import { WEB_APP_EVENTS } from '../shared/types/app-events.enum';
import { Organisation } from '../organisation/types/organisation.model';
import { SharedHelperService } from '../shared/shared-helper.service';

export enum TOKEN_KEYS {
    TOKEN = 'token',
    TOKEN_EXPIRY = 'tokenExpiry'
}

@Injectable()
export class AuthService {
    isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isOrgViewActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    orgEmployee$: BehaviorSubject<Organisation[]> = new BehaviorSubject<Organisation[]>(null);
    redirectUrl: string = '';
    authTokenValid = false;

    constructor(
        private http: HttpClient,
        private profileService: ProfileService
    ) { }

    /**
     * Try to login the user based on provided credentials
     * @param email user email
     * @param password user password
     */
    public login(email: string, password: string): Observable<AuthToken> {
        let url = `${environment.domain}${environment.api.auth.login}`;
        return this.http.post(url, {
            'email': email,
            'password': password
        }).pipe(
            map((response: HttpResponse<any>): AuthToken => {
                return (<any>response);
            })
        );
    }

    /**
     * Get user's employee data
     */
    public getUserEmployeeData() {
        let url = `${environment.domain}${environment.api.auth.employee}?response_type=full`;
        return this.http.get(url).pipe(
            tap((response: any) => {
                if (response) {
                    this.orgEmployee$.next(response.results);
                }
            })
        );
    }

    // ...
    public isAuthenticated(): boolean {
        // Check whether the token is expired and return
        // true or false
        this.isAuthenticated$.next(this.isTokenValid());
        return this.isAuthenticated$.value;
    }

    /**
     * Stores user token in local storage
     * @param token user token
     * @param expiry token expiry date
     */
    public saveUserToken(token: string, expiry: Date) {
        localStorage.setItem(TOKEN_KEYS.TOKEN, token);
        if (expiry) {
            localStorage.setItem(TOKEN_KEYS.TOKEN_EXPIRY, expiry.toString());
        }
        this.isAuthenticated$.next(true);
    }

    public logoutAllDevices() {
        this.http.post(`${environment.domain}${environment.api.auth.logoutAllDevices}`, {}).subscribe(() => {
            this.isAuthenticated$.next(false);
        })
    }

    /**
     * Removes from local storage
     */
    public removeUserToken() {
        localStorage.removeItem(TOKEN_KEYS.TOKEN);
        localStorage.removeItem(TOKEN_KEYS.TOKEN_EXPIRY);
        this.isAuthenticated$.next(false);
    }

    /**
     * Checks if token is still valid
     * @param token user token
     * @param expiry token expiry date
     */
    public isTokenValid() {
        const token = localStorage.getItem(TOKEN_KEYS.TOKEN);
        if (token) {
            this.getUserProfile();
        }
        return token ? true : false;
    }

    /**
     * Gets user profile based on auth token
     */
    public getUserProfile() {
        const url = `${environment.domain}${environment.api.user.profile}?response_type=full`;
        this.http.get(url).pipe(
            shareReplay(1),
        ).subscribe((response: any) => {
            if (response && response.results[0]) {
                this.profileService.userProfile$.next(response.results[0]);
                this.profileService.user$.next(response.results[0]);
                this.profileService.user = response.results[0];
                localStorage.setItem('username', response.results[0].username);
                localStorage.setItem('user', JSON.stringify(response.results[0]));
                this.user$.next(response.results[0]);
                this.isAuthenticated$.next(true);
                PubSub.publish(WEB_APP_EVENTS.GET_USER_TRACKER_INFO, { gp: false });
                PubSub.publish(WEB_APP_EVENTS.USER_PROFILE_LOADED);
                this.authTokenValid = true;
            }
        });
    }

    /**
     * Try to reset the user's password based on provided credentials
     * @param email user email
     */
    public forgotPassword(email: string) {
        let url = `${environment.domain}${environment.api.auth.forgotPassword}`;
        return this.http.post(url, {
            'email': email,
        });
    }

    /**
     * Try to reset the user's password based on provided credentials
     * @param email user email
     */
    public resetPassword(uid: string, token: string, resetPasswordForm:any) {
        let url = `${environment.domain}${environment.api.auth.resetPassword}?uid=${uid}&token=${token}`;
        resetPasswordForm.uid = uid;
        resetPasswordForm.token = token;
        return this.http.post(url, resetPasswordForm);
    }

    /**
     * Try to register the user based on provided credentials
     * @param first_name user first name
     * @param last_name user last name
     * @param email user email
     * @param password user password
     */
    public register(first_name: string, last_name: string, email: string, password: string, phone_number: string, inviteCode: string): Observable<AuthToken> {
        let url = `${environment.domain}${environment.api.auth.register}`;
        let payload = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password1: password,
            phone_number: phone_number,
        }
        if (inviteCode) {
            payload['invite_code'] = inviteCode;
        }
        return this.http.post(url, payload).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }


    // TODO remove other register form from all components
    _register(registerForm:any): Observable<AuthToken> {
        let url = `${environment.domain}${environment.api.auth.register}`;
        return this.http.post(url, registerForm).pipe(
            map((response: HttpResponse<any>) => {
                return (<any>response);
            })
        );
    }

    /**
 * Verifies the phone number with given code.
* @param {string} phone_number - The phone number to be verified.
* @param {string} phone_code - The verification code sent to user.
* @returns {Observable<any>} - An observable that emits the response from the server.
*/

    public verifyPhoneNumber(phone_number: string, phone_code: string): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.phoneNumberVerification}`;
        return this.http.post(url, { phone_number: phone_number, phone_code: phone_code });
    }
    /**
 * Verifies the phone number with given code.
* @param {string} phone_number - The phone number to be resend verification code to.
* @returns {Observable<any>} - An observable that emits the response from the server.
*/

    public resendVerificationCode(phone_number: string): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.resendPhoneNumberVerificationCode}`;
        return this.http.post(url, { phone_number: phone_number });
    }

    /**
* Changes the user's phone number to the specified new phone number address.
* @param {string} phone number - The new phone number to be set.
* @returns {Observable<any>} - An observable that emits the response from the server.
*/

    public changePhoneNumber(formValue: any): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.changePhoneNumber}`;
        return this.http.post(url, formValue);
    }

    /**
   * Sends the email verification email for the specified email address.
  * @param {string} email - The email address to resend the verification email to.
  * @returns {Observable<any>} - An observable that emits the response from the server.
  */

    public verifyEmail(key: string): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.emailVerification}`;
        return this.http.post(url, { key: key });
    }
    /**
   * Resends the email verification email for the specified email address.
  * @param {string} email - The email address to resend the verification email to.
  * @returns {Observable<any>} - An observable that emits the response from the server.
  */

    public resendVerificationEmail(email: string): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.resendVerificationEmail}`;
        return this.http.post(url, { email: email });
    }

    /**
 * Changes the user's email to the specified new email address.
 * @param {string} email - The new email address to be set.
 * @returns {Observable<any>} - An observable that emits the response from the server.
 */

    public changeEmail(email: string): Observable<any> {
        let url = `${environment.domain}${environment.api.auth.changeEmail}`;
        return this.http.post(url, { new_email: email });
    }

    /**
     * After Google login, share details with MMB
     */
    googleLoginToMovemeback(data: {googleToken?: string, googleCode?: string}): Observable<any> {
        const url = `${environment.domain}${environment.api.auth.googleLogin}`;
        return this.http.post(url, { 'access_token': data.googleToken, 'code': data.googleCode });
    }

    connectAccountToGoogle(data: {googleToken?: string, googleCode?: string}): Observable<any> {
        const url = `${environment.domain}${environment.api.auth.googleConnect}`;
        return this.http.post(url, { 'access_token': data.googleToken, 'code': data.googleCode });
    }

    /**
     * After Linkedin login, share details with MMB
     */
    linkedinLoginToMovemeback(linkedinToken: string): Observable<any> {
        const url = `${environment.domain}${environment.api.auth.linkedinLogin}`;
        return this.http.post(url, { 'code': linkedinToken });
    }

    getLinkedinProfileDetails(code: string, redirectUrl: string) {
        return this.http.get(`/oauth/v2/accessToken`, {
            params: {
                grant_type: "authorization_code",
                code: code,
                redirect_uri: redirectUrl,
                client_id: environment.linkedIn.clientId,
                client_secret: environment.linkedIn.cs
            }
        });
    }


    /**
     * Logout from MMB - expire the token in backend
     */
    logout(): Observable<any> {
        const url = `${environment.domain}${environment.api.auth.logout}`;
        return this.http.post(url, {});
    }

    submitContact(email: string, description: string, name: string, category: number) {
        let url = `${environment.domain}${environment.api.user.contact}`;
        if (!this.isAuthenticated$.value) {
            url = `${environment.domain}${environment.api.public.contact}`;
        }
        return this.http.post(url, {
            email,
            description,
            name,
            category
        });
    }
}
